import React from "react";
import PaymentModal from "../payment/PaymentModal";
import { Button } from "react-bootstrap";
import PaymentStatusModal from "../payment/PaymentStatusModal";
function Header() {
  const [modalShow, setModalShow] = React.useState(false);
  const [statusShow, setStatusShow] = React.useState(false);

  const handleStatusClose = () => setStatusShow(false);
  const handleStatusShow = () => setStatusShow(true);

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);
  React.useEffect(() => {
    const handleHashChange = () => {
       if (window.location.hash.includes('#/feepayment')) {
         handleShow();
       } else if (window.location.hash.includes('#/paymentstatus')) {
         handleStatusShow();
       }
    };
   
    // Add event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);
   
    // Add event listener for popstate changes
    window.addEventListener('popstate', handleHashChange);
   
    // Check the hash on initial load
    handleHashChange();
   
    // Cleanup: remove the event listeners when the component unmounts
    return () => {
       window.removeEventListener('hashchange', handleHashChange);
       window.removeEventListener('popstate', handleHashChange);
    };
   }, []);

  return (
    <div className="header">
      <PaymentModal show={modalShow} onHide={handleClose} />
      <PaymentStatusModal show={statusShow} onHide={handleStatusClose} />

      <div
        id="topbar"
        class="topbar-fullwidth light d-xl-block d-lg-block d-sm-block d-xs-block"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-none d-sm-block">
              <ul class="top-menu">
                <li>
                  <a href="#">Phone: 93466 10414,99081 54742, 99595 79374</a>
                </li>
                <li>
                  <a href="#">Email: pvidyalaya@gmail.com</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 d-sm-block text-right">
              <ul class="top-menu float-right">
                <li className="mr-2 mt-1">
                <Button variant="primary" size="sm" onClick={handleShow}>
                    Pay fees
                  </Button>
                </li>

                <li>
                  <a href="https://huezaia.com/padmavathi/" target="_blank">
                    <i class="fa fa-sign-in" aria-hidden="true"></i> Sign In
                  </a>
                </li>
                <li>
                  <a href="admission.html" target="_blank">
                    <i class="fa fa-upload" aria-hidden="true"></i> Application
                    Form
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
